<template>
  <body>
    <div style="background-color: #fff">
      <div style="width: 90%; margin: 0 auto">
        <search @onClickButton="onClickButton" />
      </div>
      <div style="width: 95%; margin: 0 auto">
        <AllScreening @onConfirm="onConfirm" @onConfirmTime="onConfirmTime" />
      </div>
    </div>

    <!-- list loging分页加载 -->
    <van-pull-refresh
      v-model="reactiveParams.list.refreshing"
      @refresh="onRefresh"
    >
      <van-list
        v-model:loading="reactiveParams.list.loading"
        :finished="reactiveParams.list.finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="divStyle"
          v-for="(item, index) in reactiveParams.list.list"
          :key="index"
        >
          <div class="flx">
            <div>班级</div>
            <div>{{ item.groupName }}</div>
          </div>
          <div class="flx">
            <div>执行班次</div>
            <div>{{ item.flight }}</div>
          </div>
          <div class="flx">
            <div>岗位模式</div>
            <div>{{ item.modeName }}</div>
          </div>
          <div class="flx">
            <div>有分</div>
            <div>{{ item.customerNonzero ? item.customerNonzero : "-" }}</div>
          </div>
          <div class="flx">
            <div>零分</div>
            <div>{{ item.customerZero ? item.customerZero : "-" }}</div>
          </div>
          <div class="flx">
            <div>净收入</div>
            <div>{{ item.netincome ? item.netincome : "-" }}</div>
          </div>
          <div class="flx">
            <div>岗位前</div>
            <div style="width: 1rem; height: 1rem">
              <img style="width: 100%; height: 100%" :src="item.picture" />
            </div>
          </div>
          <div class="flx" style="margin-top: 0.2rem">
            <van-button type="primary" size="small" @click="purchase(item)">
              收购
            </van-button>
            <van-button type="primary" size="small" @click="integral(item)">
              基分
            </van-button>
            <van-button type="primary" size="small" @click="compile(item)">
              编辑
            </van-button>
            <van-button type="danger" size="small" @click="deleteclick(item)">
              删除
            </van-button>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <!-- 添加信息 -->
    <div>
      <Add @addRoute="addRoute" />
    </div>
  </body>
</template>
<script>
  import {
    ref,
    inject,
    reactive,
    toRefs,
    provide,
    getCurrentInstance,
    onMounted,
  } from "vue";
  import AllScreening from "@/components/AllScreening/index";
  import search from "@/components/compontSearch/Search";
  import Add from "@/components/componentAdd/index";
  import Datetimesy from "@/components/componentsJs/time";
  import color from "@/components/componentsJs/color";
  import { useRoute, useRouter } from "vue-router";
  // import Image from '../../../components/componentPreview/image.vue';
  export default {
    components: {
      AllScreening,
      search,
      Add,
      // Image,
    },
    setup() {
      const useRouters = useRouter();
      color.colormounted();
      const { proxy } = getCurrentInstance();

      function onClickButton(e) {
        reactiveParams.list.from.keyword = e;
        onRefresh();
      }
      // 单次选择
      function onConfirm(e) {
        reactiveParams.list.from.companyId = e.id;
        onRefresh();
      }
      // 时间选择
      function onConfirmTime(e) {
        reactiveParams.list.from.workdate = Datetimesy.Datetimesy(e);
        reactiveParams.AllArray.resultTime = reactiveParams.list.from.workdate;
        onRefresh();
      }

      // 收购
      function purchase(item) {
        useRouters.push({
          name: "daysgroupsPurchase",
          params: { item: JSON.stringify(item) },
        });
      }

      // 积分
      function integral(item) {
        useRouters.push({
          name: "daysgroupsBasicComponent",
          query: { item: JSON.stringify(item) },
        });
      }

      // 编辑
      function compile(item) {
        useRouters.push({
          name: "daysgroupsAdd",
          query: { item: JSON.stringify(item) },
        });
      }

      // 删除
      function deleteclick() {}

      const reactiveParams = reactive({
        AllArray: {
          columns: [],
          result: "",
          resultTime: "",
          label: "",
          labelTime: "",
          placeholder: "请选择队伍",
          customFieldName: {
            text: "nametext",
          },
        },
        searchobject: {
          placeholder: "请输入班组名称/执行班次",
        },
        list: {
          refreshing: false,
          loading: false,
          finished: false,
          list: [],
          from: {
            pi: 0,
            ps: 10,
            order: "groupId.asc",
            companyId: "",
            workdate: Datetimesy.Datetimesy(),
            keyword: "",
            keywords: "groupName,flight",
          },
          totalPage: 0,
        },
      });

      // 获取队伍信息
      const list = async () => {
        let listArray = await proxy.$Api.get(
          proxy.$consultiveManagementApi.getLaCompanysList
        );
        reactiveParams.AllArray.columns = listArray.data.data;
        reactiveParams.AllArray.columns.forEach((item) => {
          item.nametext = `${item.name}|${item.pyjm}`;
        });
        // 新赋值
        reactiveParams.AllArray.result =
          reactiveParams.AllArray.columns[0].nametext;
        // 初始化id赋值
        reactiveParams.list.from.companyId =
          reactiveParams.AllArray.columns[0].id;
        reactiveParams.AllArray.resultTime = Datetimesy.Datetimesy();
      };

      // 初始化运行方法
      const onLoad = async () => {
        setTimeout(() => {
          if (reactiveParams.list.refreshing) {
            reactiveParams.list.list = [];
            reactiveParams.list.refreshing = false;
          }

          console.log(
            reactiveParams.list.from.pi,
            reactiveParams.list.totalPage
          );
          if (reactiveParams.list.from.pi <= reactiveParams.list.totalPage) {
            reactiveParams.list.from.pi = reactiveParams.list.from.pi + 1;
            ArrayList();
          } else {
            reactiveParams.list.finished = true;
          }
        }, 500);
      };

      // 菜单接口获取数据
      const ArrayList = async () => {
        let List = await proxy.$Api.get(
          proxy.$laDaysGroupsApi.GetLaDaysGroupsPage,
          reactiveParams.list.from
        );
        reactiveParams.list.totalPage = List.data.data.totalPage;
        List.data.data.data.forEach((item) => {
          reactiveParams.list.list.push(item);
        });
        console.log(reactiveParams.list.list);
        reactiveParams.list.loading = false;
      };

      // 页面刷新
      const onRefresh = async () => {
        // 清空列表数据
        reactiveParams.list.finished = false;
        reactiveParams.list.loading = true;
        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        reactiveParams.list.from.pi = 0;
        reactiveParams.list.list = [];
        onLoad();
      };
      // 页面添加
      function addRoute() {
        useRouters.push({ name: "daysgroupsAdd" });
      }
      onMounted(() => {
        list();
      });
      // 父级传值
      provide("hradertext", reactiveParams.AllArray);
      provide("Search", reactiveParams.searchobject);

      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        onClickButton,
        addRoute,
        list,
        onLoad,
        onRefresh,
        onConfirm,
        onConfirmTime,
        purchase,
        integral,
        compile,
        deleteclick,
      };
    },
  };
</script>
<style scoped>
  .divStyle {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.2rem;
    background-color: #fff;
    padding: 0.2rem;
    border-radius: 0.2rem;
    overflow: hidden;
    box-sizing: border-box;
  }
  body {
    height: 100%;
    background-color: #f3f3f3;
  }
</style>
