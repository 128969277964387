<template>
  <div>
    <!-- 单次选择筛选 -->
    <van-field
      v-model="userLocation.result"
      is-link
      readonly
      name="picker"
      :label="userLocation.label"
      :placeholder="userLocation.placeholder"
      @click="showPicker = true"
    />
    <!-- 时间选择 -->
    <van-field
      v-model="userLocation.resultTime"
      is-link
      readonly
      name="datetimePicker"
      :label="labelTime"
      placeholder="点击选择时间"
      @click="showPickerTime = true"
    />
    <!-- 单次选择弹框 -->
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="userLocation.columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        :columns-field-names="userLocation.customFieldName"
      />
    </van-popup>
    <!-- 时间选择弹框 -->
    <van-popup v-model:show="showPickerTime" position="bottom">
      <van-datetime-picker
        v-model="reactiveParams.currentDate"
        type="date"
        @confirm="onConfirmTime"
        @cancel="showPickerTime = false"
        :columns-order="['year', 'month', 'day']"
        :formatter="formatter"
      />
    </van-popup>
  </div>
</template>
<script>
  import {
    ref,
    inject,
    reactive,
    toRefs,
    provide,
    getCurrentInstance,
    onMounted,
  } from "vue";
  export default {
    emits: ["onConfirm", "onConfirmTime"],
    setup(props, context) {
      const userLocation = inject("hradertext");
      const { proxy } = getCurrentInstance();
      const reactiveParams = reactive({
        currentDate: new Date(),
        result: [],
        label: "",
        labelTime: "",
        placeholder: "",
        showPickerTime: false,
        showPicker: false,
        columns: [],
      });
      const formatter = (type, val) => {
        if (type === "year") {
          return val + "年";
        }
        if (type === "month") {
          return val + "月";
        }
        if (type === "day") {
          return val + "日";
        }
        return val;
      };
      // 单次选择确认
      function onConfirm(e) {
        console.log(e);
        context.emit("onConfirm", e);
      }
      // 单次时间选择
      function onConfirmTime(e) {
        console.log(e);
        context.emit("onConfirmTime", e);
        reactiveParams.showPickerTime = false;
      }
      onMounted(() => {});
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        onConfirmTime,
        onConfirm,
        userLocation,
        formatter,
      };
    },
  };
</script>
<style scoped></style>
